import React, { useState, useEffect , useRef } from 'react';
import { Section, Map, Extras, Exclusives, Amenities, Menu, Footer } from './components';
import { ReactComponent as MenuIcon } from '../../../assets/icons/menu2line.svg';
import Carousel from 'react-bootstrap/Carousel';
import { CARUSELDATA } from './constants';
import MAKLogo from "../../../assets/images/MAKLogoSystem.png"
import './style.scss';
import StartTour from './components/StartTour';
import { Image } from 'react-bootstrap';
import moment from 'moment';

const Fontainebleau = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 576);
  const tourRef = useRef(null)

  const scrollTo3dTour = () =>  tourRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

  const [openMenu, setOpenMenu] = useState(false)

  const toggleMenu = () => {
    setOpenMenu(!openMenu)
  }


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 576);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const queryParams = new URLSearchParams(window.location.search)
  let checkIn       = queryParams.get('checkIn')
  let checkOut      = queryParams.get('checkOut')
  const adults      = queryParams.get('adults')
  const children      = queryParams.get('children')
  if(!checkOut || !checkIn){
    const location = window.location.href
    const basePath = location.split("?")
    const query = `?checkIn=${moment().format("YYYY-MM-DD")}&checkOut=${moment().add(3, "days").format("YYYY-MM-DD")}&adults=${adults || 0}&children=${children || 0}&city=`;
    window.location.href = basePath[0] + query
  }

  return (
    <div id='about' className='fb'>
      <div className='fb-menu'>
        <p className='fb-menu-text' onClick={() => window.location.href = 'https://vacations.makrealty.com/'}>Back</p>
        <div className='fb-menu-buttonDiv'>
          <button className='fb-menu-buttonDiv-button' onClick={scrollTo3dTour}>START 3D TOUR</button>
          <MenuIcon style={{ cursor: 'pointer' }} onClick={toggleMenu} />
          <Menu isOpen={openMenu} toggleMenu={toggleMenu} />
        </div>
      </div>
      <div className='fb-welcome'>
        <div className='fb-welcome-gradient' />
        <Carousel controls={false}className='fb-welcome-carusel'>
          {CARUSELDATA.map(({ title, text, imgSrc, imgMobile }, index) => (
            <Carousel.Item className='fb-welcome-carusel-item' key={index}>
              <div className='fb-welcome-carusel-textbox'>
              <Image src={MAKLogo} className='makLogo' />
                <h1>{title}</h1>
                <p>{text}</p>
              </div>
              <img alt={`carouselImg${index}`} src={isMobile && imgMobile ? imgMobile : imgSrc}
                   className='fb-welcome-carusel-item-background' />
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
      <div className="fb-welcome-buttonDiv">
        <button onClick={scrollTo3dTour}
                className="fb-welcome-buttonDiv-button">Reserve your stay
        </button>
      </div>
      <div ref={tourRef}>
      <StartTour  />
      </div>
      <Section isMobile={isMobile} />
      <Map isMobile={isMobile} />
      <Extras isMobile={isMobile} />
      <Exclusives />
      <Amenities />
      <Footer />
    </div>
  )
}

export default Fontainebleau
